









































import { Component, Vue } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';

import { DispatchModule } from '@/store/index';
import ExportFilters from '../ExportFilters/ExportFilters.vue';
import { DispatchType } from '../../models/dispatch.model';
import BookingModal from './BookingModal.vue';
import BookingFilters from '../ExportFilters/BookingFilters.vue';
import { convertAPIToFormat } from '@/utils/date.util';
import Loading from '@/shared/components/Loading.vue';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import {
  BookingCategoriesHeaders,
  BookingTableHeaders
} from '../models/tables/booking';
import { ToastHelper } from '@/utils/toast.util';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: {
    ExportFilters,
    Loading,
    BookingFilters,
    BookingModal,
    Fragment,
    TmsTable
  }
})
export default class Booking extends Vue {
  convertAPIToFormat = convertAPIToFormat;

  routeName = 'export';
  bookingId = null;
  isCompleteActive = false;
  loading = false;
  dispatchModule = DispatchModule;
  isShown = {
    first: true,
    second: true,
    third: true
  };

  categoryTableHeaders = BookingCategoriesHeaders;
  columnHeaders = BookingTableHeaders;

  constructor() {
    super();
  }

  async onPaginationChange() {
    await this.dispatchModule.search({
      dispatchType: DispatchType[this.routeName.substr(0, 3).toUpperCase()],
      category: this.$route.name.replace(`${this.routeName}-`, '')
    });
  }

  get editableData() {
    return this.dispatchModule.dispatches;
  }

  get isLastPage(): boolean {
    return !this.dispatchModule.meta.lastKey;
  }

  changeIsShownHandler(param) {
    this.isShown[param] = !this.isShown[param];
  }

  showDetails(id) {
    this.bookingId = id;
    this.$bvModal.show('booking-modal');
  }

  showModal() {
    this.bookingId = null;
    this.$bvModal.show('booking-modal');
  }

  async updateTable() {
    this.loading = true;

    await this.dispatchModule.search({
      dispatchType: DispatchType[this.routeName.substr(0, 3).toUpperCase()],
      category: this.$route.name.replace(`${this.routeName}-`, '')
    });

    this.loading = false;
  }

  checkIsCompleteActive(data) {
    const dispatch = this.dispatchModule.dispatches.find(i => i.id === data.id);
    dispatch.ck = data.ck;
    this.isCompleteActive = this.dispatchModule.dispatches.some(i => i.ck);
  }

  onInputChange({ field, data }) {
    switch (field) {
      case 'ck':
        this.checkIsCompleteActive(data);
        break;
    }
  }

  onAction({ key, data }: { key: string; data: any }) {
    this.showDetails(data.id);
  }
}
