




















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AskModal extends Vue {
  @Prop({ default: 'Are you sure that you want to delete it?' })
  message: string;
}
